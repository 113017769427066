import jpPrefecture from "jp-prefecture";

document.addEventListener(
  'DOMContentLoaded',
  () => {
    objectFitImages('img.img-o-cover');
    AOS.init({
      once: true,
      duration: 1000,
      easing: 'ease'
    });
  },
  false
);

const mx768 = window.matchMedia('(max-width: 768px)');
const header = document.querySelector('.header');
const headerSp = document.querySelector('.header--sp');
const pageTop = document.querySelector('.page-top');
let lastVertPos = window.pageYOffset;

function checkYOffset() {
  if (lastVertPos > 200) {
    header.classList.add('scrolled');
    pageTop.classList.add('scrolled');
  } else {
    header.classList.remove('scrolled');
    pageTop.classList.remove('scrolled');
  }

  if (mx768.matches) {
    if (lastVertPos > 200) {
      headerSp.classList.add('scrolled');
    } else {
      headerSp.classList.remove('scrolled');
    }
  }
}

checkYOffset();

window.addEventListener('scroll', function() {
  lastVertPos = window.pageYOffset;

  checkYOffset();
});

header.addEventListener('click', function(e) {
  e.preventDefault();

  let hash = e.target.getAttribute('href');

  if (hash !== '#') {
    let yOffset = - 130;
    let elToScroll = document.querySelector(e.target.getAttribute('href'));
    const y = elToScroll.getBoundingClientRect().top + window.pageYOffset + yOffset;

    window.scrollTo({
      top: y,
      behavior: 'smooth'
    });
  } else {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }
});

headerSp.addEventListener('click', function(e) {
  e.preventDefault();

  let hash = e.target.getAttribute('href');

  if (hash !== '#') {
    let yOffset = - 130;
    let elToScroll = document.querySelector(`${hash}`);
    const y = elToScroll.getBoundingClientRect().top + window.pageYOffset + yOffset;

    window.scrollTo({
      top: y,
      behavior: 'smooth'
    });

    headerSp.classList.remove('toggled');
    document.body.classList.remove('overflow');
  } else {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
    headerSp.classList.remove('toggled');
    document.body.classList.remove('overflow');
  }
});

const headerToggle = document.querySelector('.header__toggle') || null;

if (headerToggle) {
  headerToggle.addEventListener('click', function () {
    headerSp.classList.toggle('toggled');
    if (mx768.matches) {
      document.body.classList.toggle('overflow');
    }
  });
}

pageTop.addEventListener('click', function(e) {
  e.preventDefault();

  window.scrollTo({
    top: 0,
    behavior: 'smooth'
  });
});

// Load all prefecture
const prefectureHook = document.querySelector("#prefecture") || null;
let arrPref;

if (prefectureHook) {
  let prefectures = jpPrefecture.getAll("pref");
  arrPref = jpPrefecture.getAllPref("name");

  prefectureHook.innerHTML = '';

  const prefPlaceholder = document.createElement('option');
  prefPlaceholder.textContent = '都道府県選択';
  prefPlaceholder.setAttribute('selected', true);
  prefPlaceholder.setAttribute('disabled', true);
  prefectureHook.appendChild(prefPlaceholder);

  prefectures.forEach((prefecture) => {
    const tmpEl = document.createElement("option");
    tmpEl.value = prefecture.name;
    tmpEl.textContent = prefecture.name;
    prefectureHook.appendChild(tmpEl);
  });

  prefectureHook.addEventListener("change", function() {
    prefectureHook.style.color = "#000";
    prefectureHook.style.backgroundColor = "white";
  });
}

const postal = document.querySelector('#postal') || null;
const postalVal = document.querySelector('#hpost') || null;
const btnSearchPostal = document.querySelector('.js-postal-search button[type="submit"]');
let postalInp = '';
let postalRes = '';
let typingTimer = 0;
let doneTypingInterval = 200;

if (postal) {
  postal.addEventListener('keyup', function(e) {
    clearTimeout(typingTimer);
    if (this.value) {
      postalInp = this.value;
      typingTimer = setTimeout(getPostalRes, doneTypingInterval);
      AjaxZip3.zip2addr(this, '', 'hpost', '市区町村名');
    }
  });

  btnSearchPostal.addEventListener("click", function(e) {
    e.preventDefault();

    if (postalInp !== '') {
      AjaxZip3.zip2addr(postalInp, '', 'hpost', '市区町村名');
      typingTimer = setTimeout(getPostalRes, doneTypingInterval);
    }
  });
}

function getPostalRes() {
  // console.log(arrPref.indexOf(postalRes));
  postalRes = postalVal.value;
  if (arrPref.indexOf(postalRes) !== -1) {
    prefectureHook.value = postalVal.value;
  }
}

const mwwpConfirm = document.querySelector('.mw_wp_form_confirm') || null;
const mwwpComplete = document.querySelector('.mw_wp_form_complete') || null;
const contactSteps = document.querySelectorAll('.contact__step') || null;

if (mwwpConfirm) {
  document.querySelector('#contact').classList.add('contact--confirm');
  contactSteps[0].classList.remove('active');
  contactSteps[1].classList.add('active');
}

if (mwwpComplete) {
  document.querySelector('.contact__step.active').classList.remove('active');
  contactSteps[2].classList.add('active');
}